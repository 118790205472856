import $ from "jquery";
// Get "access_full_whitepaper_content" cookie.
// Set whitepaper-unlocked class to change lock icon to unlock icon.

const whitepaperCookieValue = document.cookie
  .split("; ")
  .find((row) => row.startsWith("access_full_whitepaper_content"))
  ?.split("=")[1];

var whitepaperHolder = document.getElementById("js-whitepaper-holder");

if (whitepaperCookieValue) {
  var encodedString = whitepaperCookieValue;
  var decodedString = decodeURIComponent(encodedString);
  var jsonArray = JSON.parse(decodedString);
  var visitor_name = jsonArray.visitor_name;
  var visitor_business = jsonArray.visitor_business;
  var visitor_email = jsonArray.visitor_email;
  var whitepaper_id_holder = document.getElementById("js-whitepaper-id");
  var whitepaper_id = 0;
  var post_type = "";

  if (whitepaper_id_holder) {
    whitepaper_id = parseInt(whitepaper_id_holder.dataset.postid);
    post_type = whitepaper_id_holder.dataset.posttype;
  }

  var whitepaperGrids = document.querySelectorAll(".js-whitepaper-grid");
  if (whitepaperGrids.length > 0) {
    whitepaperGrids.forEach((element) => {
      element.classList.add("whitepaper-unlocked");
    });
  }

  var whitepaperFormHolder = document.querySelector(".js-unlock-content-form");
  if (whitepaperFormHolder) {
    whitepaperFormHolder.classList.add("hide");
  }

  if (whitepaperHolder) {
    whitepaperHolder.classList.add("active");
  }
}

var pageLoadStatus = document.getElementById("js-page-loaded");
var postTypeChecker = "";

if (pageLoadStatus) {
  postTypeChecker = pageLoadStatus.dataset.posttype;
}

if (
  whitepaperCookieValue &&
  !document.querySelector(".js-page-loaded") &&
  postTypeChecker == "whitepapers"
) {
  window.location.reload();

  if (whitepaperHolder) {
    whitepaperHolder.scrollIntoView();
  }
}

// Ajax request on page load
class WhitepaperVisitor {
  constructor() {
    if (whitepaperCookieValue) {
      this.initWhitepaperVisitor();
    }
  }

  /**
   * Add whitepaper visitor
   */
  initWhitepaperVisitor() {
    $(window).on("load", function (e) {
      if (
        whitepaperCookieValue &&
        whitepaper_id &&
        post_type == "whitepapers"
      ) {
        var data = {
          action: "whitepaper_visitor",
          whitepaper_id: whitepaper_id,
          visitor_name: visitor_name,
          visitor_business: visitor_business,
          visitor_email: visitor_email
        };

        $.ajax({
          method: "post",
          url: ajaxurl,
          data: data,
          success: function (response) {
            if (response) {
            }
          },
          error: function (error) {}
        });
      }
    });
  }
}

new WhitepaperVisitor();
