
import $ from "jquery";

class Nonce {
  constructor() {
    this.createNonce();
  }

  /**
   * Adds a WP nonce to each js-nonce class so we can cache
   * otherwise static content.
   */
  createNonce() {
    $(".js-nonce").each(function() {
      /**
       * Check if this field is an input field.
       *
       * @var {bool}
       */
      const isInputField = $(this).is("input");

      /**
       * Get the nonce ID to generate a token for.
       *
       * @var {string}
       */
      const nonceType = $(this).data("nonce-type");

      /**
       * Data to send.
       *
       * @var {object}
       */
      const data = {
        action: "_get_nonce",
        nonce_type: nonceType
      };

      // Send the request.
      $.ajax({
        method: "get",
        url: ajaxurl,
        data: data,
        success: response => {
          // Append the token based on the element type.
          if (isInputField) {
            // <input> field.
            this.value = response.nonce;
          } else {
            // Other fields, like <button>.
            this.dataset.nonce = response.nonce;
          }
        },
        error: response => {
          console.error(response);
        }
      });
    });
  }
}

new Nonce();
