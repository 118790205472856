import $ from "jquery";

class LoadMore {
  constructor() {
    this.initLoadMore();
  }

  /**
   * Load more posts when button loadmore is clicked
   */
  initLoadMore() {
    $(".button--loadmore").on("click", function (e) {
      var me = $(this);

      var post_type = me.attr("data-post-type");

      if (post_type == "post") {
        var post_list = me.parents("#articles").find(".grid");
      }

      var data = {
        action: "load_more",
        post_type: post_type,
        no_posts: parseInt(me.attr("data-posts")),
        no_posts_loaded: parseInt(me.attr("data-postsloaded")),
        post_cat_id: parseInt(me.attr("data-post-cat-id"))
      };

      me.attr("disabled", true);
      me.addClass("button--loading");

      $.ajax({
        method: "post",
        url: ajaxurl,
        data: data,
        success: function (response) {
          if (response) {
            // add response HTML to markup
            post_list.append(response.html);

            // update loadmore button data-attr for post offset in next load
            var new_offset = data.no_posts + data.no_posts_loaded;
            me.attr("data-postsloaded", new_offset);

            // Hide load more button if no more posts
            if (response.show_load_more == false) {
              me.hide();
            } else {
              me.show();
            }

            // Get correct text for button depending on language
            if (siteLang === "en") {
              $("#articles .js-read-more").text("Learn more");
            } else {
              $("#articles .js-read-more").text("Läs mer");
            }
          }
          me.attr("disabled", false);
          me.removeClass("button--loading");
        },
        error: function (error) {
          me.attr("disabled", false);
          me.removeClass("button--loading");
        }
      });
    });
  }
}

new LoadMore();

class LoadMoreWhitepaper {
  constructor() {
    this.initLoadMoreWhitepaper();
  }

  /**
   * Load more posts when button loadmore is clicked
   */
  initLoadMoreWhitepaper() {
    $(".js-button-loadmore").on("click", function (e) {
      var me = $(this);

      var post_type = me.attr("data-post-type");

      if (post_type == "whitepapers") {
        var post_list = me.parents("#whitepapers").find(".grid");
      }

      var data = {
        action: "load_more_whitepapers",
        post_type: post_type,
        no_posts_per_page: parseInt(me.attr("data-posts-per-page")),
        no_posts_loaded: parseInt(me.attr("data-postsloaded")),
        no_posts_found: parseInt(me.attr("data-found-posts")),
        post_cat_id: parseInt(me.attr("data-post-cat-id"))
      };

      me.attr("disabled", true);
      me.addClass("button--whitepaper-loading");

      $.ajax({
        method: "post",
        url: ajaxurl,
        data: data,
        success: function (response) {
          if (response) {
            // add response HTML to markup
            post_list.append(response.html);

            // update loadmore button data-attr for post offset in next load
            var new_offset = data.no_posts_per_page + data.no_posts_loaded;
            me.attr("data-postsloaded", new_offset);

            // Hide load more button if no more posts
            if (data.no_posts_found <= new_offset) {
              me.hide();
            } else {
              me.show();
            }
          }
          me.attr("disabled", false);
          me.removeClass("button--whitepaper-loading");
        },
        error: function (error) {
          me.attr("disabled", false);
          me.removeClass("button--whitepaper-loading");
        }
      });
    });
  }
}

new LoadMoreWhitepaper();
