import $ from "jquery";
import Flickity from "flickity";
import "flickity-as-nav-for";

export default {
  init() {
    /*
     * Add loaded class when document ready.
     */
    $(document).ready(function () {
      $("body").addClass("site--loaded");
    });

    /**
     * Flickity slider carousel with image navigation (asNavFor)
     */

    // Only start flickity if carousel is on the page
    if ($(".carousel-main")[0]) {
      // 1st carousel, main
      var flkty = new Flickity(".carousel-main", {
        pageDots: false,
      });

      // 2nd carousel, navigation
      var flktyNav = new Flickity(".carousel-nav", {
        asNavFor: ".carousel-main",
        contain: true,
        pageDots: false,
        prevNextButtons: false,
      });

      setTimeout(function () {
        /* Resize after timeout to avoid height bug */
        flkty.resize();
        flktyNav.resize();
      }, 300);
    } else {
      // Do nothing if no carousel
    }

    if ($(".js-testimonial-carousel")[0]) {
      // 1st carousel, main
      var flkty = new Flickity(".js-testimonial-carousel", {
        cellAlign: "left",
        pageDots: false,
        autoPlay: true,
        wrapAround: true
      });
    }

    /**
     * Trigger menu on click
     */
    $(".js-menu-trigger").on("click", function (e) {
      e.preventDefault();
      $("body").toggleClass("menu--active");
    });

    $("#site-overlay").on("click", function (e) {
      e.preventDefault();
      $("body").removeClass("menu--active");
    });

    /**
     * Insert submeny trigger when menu-item has children
     */
    $("<div class='sub-menu-trigger js-sub-menu-trigger'></div>").insertAfter(
      "li.menu-item-has-children > a"
    );

    /**
     * add class to menu after users scrolls certain amount of pixels
     */
    $(window).on("load scroll", function (e) {
      e.preventDefault();
      var scroll = $(window).scrollTop();
      var header = $(".header");
      if (scroll >= 60) {
        header.addClass("is--scrolled");
      } else {
        header.removeClass("is--scrolled");
      }
    });

    /**
     * Submenu toggle
     */
    $(".js-sub-menu-trigger").click(function (e) {
      e.preventDefault();
      $(this).toggleClass("open");
      $(this)
        .siblings(".menu__sub-menu")
        .slideToggle("fast")
        .toggleClass("active");
    });

    /**
     * Scrolldown button
     */
    $(".js-scrolldown").click(function (e) {
      e.preventDefault();
      $("html, body").animate(
        {
          // Scroll to top of next container
          scrollTop: $(".container").offset().top - 120,
        },
        500
      );
    });

    /**
     * Ajax filtering on products. Load products with selected filter on click.
     */

    $(document).on("click", "ul.product-term-list li a", function (e) {
      e.preventDefault();
      ajax_products_filter_terms($(this).attr("data-term"));
      $(this)
        .parent()
        .addClass("product-term-list__item--active")
        .addClass("product-term-list__item--loading");
      $(this)
        .parent()
        .siblings()
        .removeClass("product-term-list__item--active");
    });

    /* Function, get products with this term ID */
    function ajax_products_filter_terms(termID) {
      $.ajax({
        type: "POST",
        /* admin-ajax url from variable in footer.twig */
        url: adminAjaxURL,
        data: {
          action: "products_filter_terms",
          term: termID,
        },
        success: function (response) {
          /* Put response in this element */

          $("ul.product-term-list li").removeClass(
            "product-term-list__item--loading"
          );

          $(".grid.grid--products").html(response);
          return false;
        },
      });
    }

    /**
     * Ajax filtering on services. Load services with selected filter on click.
     */

    $(document).on("click", "ul.service-term-list li a", function (e) {
      e.preventDefault();
      ajax_services_filter_terms($(this).attr("data-term"));
      $(this)
        .parent()
        .addClass("service-term-list__item--active")
        .addClass("service-term-list__item--loading");
      $(this)
        .parent()
        .siblings()
        .removeClass("service-term-list__item--active");
    });

    /* Function, get services with this term ID */
    function ajax_services_filter_terms(termIDservices) {
      $.ajax({
        type: "POST",
        /* admin-ajax url from variable in footer.twig */
        url: adminAjaxURL,
        data: {
          action: "services_filter_terms",
          term: termIDservices,
        },
        success: function (response) {
          /* Put response in this element */

          $("ul.service-term-list li").removeClass(
            "service-term-list__item--loading"
          );

          $(".grid.grid--services").html(response);
          return false;
        },
      });
    }

    /**
     * Ajax filtering on whitepapers. Load whitepapers with selected filter on click.
     */

    $(document).on("click", "ul.whitepaper-term-list li a", function (e) {
      e.preventDefault();
      var me = $(this);
      var termId = me.attr("data-term");
      var postsPerPage = parseInt(
        me.parents(".whitepaper-term-list").attr("data-post-per-page")
      );
      var postsLoaded = parseInt(
        me.parents(".whitepaper-term-list").attr("data-postsloaded")
      );

      me.parents(".whitepaper-term-list-holder").toggleClass("is-active");
      me.parents(".whitepaper-term-list-holder")
        .find(".js-term-button")
        .find(".term-button-text")
        .text(me.text());

      var loadmoreButton = me
        .parents("#whitepapers")
        .find(".js-button-loadmore");
      loadmoreButton.attr("data-post-cat-id", termId);
      loadmoreButton.attr("data-postsloaded", postsPerPage);

      ajax_whitepapers_filter_terms(
        termId,
        postsPerPage,
        postsLoaded,
        loadmoreButton
      );
      $(this)
        .parent()
        .addClass("whitepaper-term-list__item--active")
        .addClass("whitepaper-term-list__item--loading");
      $(this)
        .parent()
        .siblings()
        .removeClass("whitepaper-term-list__item--active");
    });

    /* Function, get whitepapers with this term ID */
    function ajax_whitepapers_filter_terms(
      termId,
      postsPerPage,
      postsLoaded,
      loadmoreButton
    ) {
      $.ajax({
        type: "POST",
        /* admin-ajax url from variable in footer.twig */
        url: adminAjaxURL,
        data: {
          action: "whitepapers_filter_terms",
          term: termId,
          posts_per_page: postsPerPage,
          posts_loaded: postsLoaded,
        },
        success: function (response) {
          /* Put response in this element */
          loadmoreButton.attr("data-found-posts", response.found_posts);
          if (response.show_load_more) {
            loadmoreButton.show();
          } else {
            loadmoreButton.hide();
          }
          $("ul.whitepaper-term-list li").removeClass(
            "whitepaper-term-list__item--loading"
          );

          $(".grid.grid--whitepaper").html(response.html);
          return false;
        },
      });
    }

    // term dropdown for mobile
    $(document).on("click", ".js-term-button", function (e) {
      $(this).parents(".whitepaper-term-list-holder").toggleClass("is-active");
    });

    /**
     * Flip cards
     */

    $("#articles").on("click", ".js-card-flip", function (e) {
      e.preventDefault();
      // remove all other flipped cards first
      $(".grid__item")
        .not(this)
        .closest(".grid__item")
        .removeClass("card-flipped");

      // add class flipped to closest
      $(this)
        .closest(".grid__item")
        .removeClass("card-unflipped")
        .addClass("card-flipped");
    });

    // Flip back to front card
    $("#articles").on("click", ".js-card-flip-back", function (e) {
      e.preventDefault();
      $(this)
        .closest(".grid__item")
        .removeClass("card-flipped")
        .addClass("card-unflipped");
    });

    // On mouse leave, remove all flipped classes caused by the trigger buttons
    $("#articles").on("mouseleave", ".grid__item", function (e) {
      e.preventDefault();
      $(this).removeClass("card-flipped").removeClass("card-unflipped");
    });

    /**
     * Accordion. Trigger foldout by click (For example FAQ)
     */
    $(".js-accordion-trigger").on("click", function (e) {
      e.preventDefault();
      // Slide toggle foldout on trigger-click
      $(this).next(".accordion__foldout").slideToggle(200);

      // Toggle class for arrow
      $(this).parent().toggleClass("accordion__list-item--active");
    });

    /**
     * Set 404 hero-block height
     */
    $(window).on("load resize", function () {
      if (
        window.matchMedia("(min-width: 1024px)").matches &&
        $("body").is(".error404")
      ) {
        var windowHeight = $(window).height();
        var footerHeight = $(".footer").height();
        var heroHeight = windowHeight - footerHeight;
        $(".hero-block").height(heroHeight);
      } else if (
        !window.matchMedia("(min-width: 1024px)").matches &&
        $("body").is(".error404")
      ) {
        $(".hero-block").height("auto");
      }
    });

    // partner popup
    var popupWrapper = document.querySelectorAll(".js-partner-popup-wrapper");
    if (popupWrapper.length > 0) {
      popupWrapper.forEach((element) => {
        var popupTrigger = element.querySelector(".js-partner-popup-trigger");
        var popup = element.querySelector(".js-partner-popup");
        var popupContainer = element.querySelector(".partner-popup__container");
        var closeButton = element.querySelector(".js-button-close");

        popupTrigger.addEventListener("click", function (e) {
          popup.classList.toggle("active");
        });

        closeButton.addEventListener("click", function (e) {
          popup.classList.toggle("active");
        });

        // hide the dropdown outside click
        window.addEventListener("click", function (e) {
          if (
            !popupContainer.contains(e.target) &&
            !popupTrigger.contains(e.target)
          ) {
            if (popup.classList.contains("active")) {
              popup.classList.toggle("active");
            }
          }
        });
      });
    }
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
